<template src="./Calendar.html"></template>

<script>
import calendarService from "../../services/Calendar/Index";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "calendar",
  data() {
    return {
      events: [],
      dialogConfig: {
        createButtonLabel: "Crear",
        title: "Crear Evento",
        fields: [
          {
            name: "name",
            label: "Nombre del evento",
          },
          {
            name: "comments",
            type: "textarea",
            label: "Comentarios",
          },
        ],
      },
      now: moment(),
      initialView: "month",
    };
  },
  mounted() {
    if (this.user) {
      this.getEventCalendar();
    }
  },
  methods: {
    async getEventCalendar() {
      try {
        const startDate = moment(this.now).startOf(this.initialView).toDate();
        const endDate = moment(this.now).endOf(this.initialView).toDate();
        this.events = await calendarService.getEventCalendarUser(
          startDate,
          endDate
        );
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    eventDisplay(event) {
      return event.name + " - " + event.comments;
    },
    async eventCreated(event) {
      if (event.endTime < event.startTime) {
        this.$buefy.toast.open({
          duration: 8000,
          message: "La hora final no puede ser menor a la inicial",
          position: "is-top",
          type: "is-warning",
        });
      } else {
        this.createEvent(event);
      }
    },
    async createEvent(event) {
      try {
        await calendarService.createCalendarEvent(event);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Evento creado",
          position: "is-top",
          type: "is-primary",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    monthChanged(newDate) {
      const nextDate = moment(newDate).startOf(this.initialView).toDate();
      const nextEndDate = moment(newDate).endOf(this.initialView).toDate();
      this.changeDate(nextDate, nextEndDate);
    },
    async changeDate(nextDate, nextEndDate) {
      try {
        this.events = [];
        this.events = await calendarService.getEventCalendarUser(
          nextDate,
          nextEndDate
        );
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style lang="scss" scoped src="./Calendar.scss">
</style>