var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar"},[_c('vue-scheduler',{attrs:{"min-date":null,"max-date":null,"labels":{
        today: 'Hoy',
        back: 'Atrás',
        next: 'Siguiente',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        all_day: 'Todo el día'
    },"time-range":[8,20],"available-views":['month', 'week', 'day'],"initial-date":_vm.now,"initial-view":_vm.initialView,"use12":"","show-time-marker":false,"show-today-button":false,"eventDisplay":_vm.eventDisplay,"events":_vm.events,"event-dialog-config":_vm.dialogConfig},on:{"event-created":_vm.eventCreated,"month-changed":_vm.monthChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }